<template>
  <b-modal
      ref="edit-modal"
      id="composeModal"
      size="xl"
      hide-header
      @ok="save"
      @cancel="close"
      ok-title="Salva"
      cancel-title="Chiudi"
  >
    <h4 class="mb-4">Modifica Caregiver</h4>
    <b-row>
      <!-- name -->
      <b-col cols="12" lg="6" class="mb-3">
        <h6>Name</h6>
        <b-form-input
            v-model="editedItem.name"
            placeholder="Inserisci Nome"
            name="name-group"
            size="lg"
        ></b-form-input>
      </b-col>
      <!-- email -->
      <b-col cols="12" lg="6" class="mb-3">
        <h6>Email</h6>
        <b-form-input
            v-model="editedItem.email"
            placeholder="Inserisci email"
            name="name-group"
            size="lg"
        ></b-form-input>
      </b-col>
      <!-- dob -->
      <b-col cols="12" lg="6" class="mb-3">
        <h6>Data di nascita</h6>
        <b-form-datepicker
            id="datepicker-full-width"
            v-model="editedItem.dob"
            placeholder="Seleziona Data"
            menu-class="w-100"
            calendar-width="100%"
            :min="new Date((new Date()).getFullYear()-120, (new Date()).getMonth(), (new Date()).getDay() )"
            :max="new Date((new Date()).getFullYear()-18, (new Date()).getMonth(), (new Date()).getDay() )"
            class="mb-2"
            :hide-header="false"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="it"
            size="lg"
        ></b-form-datepicker>
      </b-col>
      <!-- cod. fiscale -->
      <b-col cols="12" lg="6" class="mb-3">
        <h6>Codice Fiscale</h6>
        <b-form-input
            v-model="editedItem.fiscal_code"
            placeholder="Lunghezza massima 16 caratteri"
            name="name-group"
            :maxlength="16"
            @keyup="fiscalCode"
            size="lg"
        ></b-form-input>
      </b-col>
      <!-- sesso -->
      <b-col cols="12" lg="3" class="mb-3">
        <b-form-group v-slot="{ ariaDescribedby }">
          <h6>Sesso</h6>
          <b-form-radio-group
              v-model="editedItem.gender"
              :options="[
                    { text: 'Uomo', value: 'man' },
                    { text: 'Donna', value: 'woman' },
                  ]"
              :aria-describedby="ariaDescribedby"
              name="radio-inline"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
      <!-- tel -->
      <b-col cols="12" lg="3" class="mb-3">
        <h6>Numero di telefono</h6>
        <b-form-input type="tel" v-model="editedItem.phone_number" placeholder=""></b-form-input>
      </b-col>
      <!-- tags -->
      <b-col cols="12" lg="6" class="mb-3">
        <SelectTag ref="selectTag" @setSelect="(value) => editedItem.tags = value" />
      </b-col>
      <!-- gmaps -->
      <b-col cols="12" lg="12" class="mb-3">
        <h6>Indirizzo di residenza</h6>
        <GMapAutocomplete ref="gmaps_address" @setPlace="(value) => editedItem.residence_address = value" />
      </b-col>
      <!-- note -->
      <b-col cols="12" lg="12" class="mb-3">
        <h6>Note</h6>
        <b-form-textarea
            id="textarea-auto-height"
            rows="3"
            max-rows="8"
            v-model="editedItem.notes"
        ></b-form-textarea>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "EditCaregiver",
  components: {
    SelectTag: () => import('@/views/tags/components/SelectTags'),
    GMapAutocomplete: () => import("@/components/gmaps/Autocomplete"),
  },
  data() {
    return {
      editedIndex: '',
      editedItem: {},
      autoCompleteOptions: {
        componentRestrictions: {
          country: ['IT'],
        },
      },
    }
  },
  methods: {
    async save() {
      const res = await this.axios.post('caregiver', this.editedItem)
      this.$swal.fire({
        title: 'Salvataggio',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
    },
    close() {
      this.$refs["edit-modal"].hide();
      setTimeout(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      }, 300);
    },
    fiscalCode() {
      this.editedItem.patient.fiscal_code = this.editedItem.patient.fiscal_code.toUpperCase()
      this.editedItem.caregiver.fiscal_code = this.editedItem.caregiver.fiscal_code.toUpperCase()
    },
  }
}
</script>

<style scoped>

</style>